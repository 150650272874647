import {tns} from "tiny-slider/src/tiny-slider.module"

if (document.querySelector('.slide-wrapper')) {

    var slider = tns({
        container: ".slide-wrapper",
        responsive: {
            0: {
                items: 1
            },
            768: {
                items: 1
            }
        },
        slideBy: "page",
        autoplay: true,
        autoplayTimeout: 15000,
        mouseDrag: true,
        autoplayHoverPause: true,
        autoplayButtonOutput: false,
        nav: false,
        autoplayButton: false,
        controls: false
    });


    if (document.querySelector('.prev')) {
        document.querySelector('.prev').onclick = function () {
            slider.goTo('prev');
        };
    }

    if (document.querySelector('.next')) {

        document.querySelector('.next').onclick = function () {
            slider.goTo('next');
        };
    }

}

if (document.querySelector('.slide-wrapper-equipe')) {

    var slider2 = tns({
        container: ".slide-wrapper-equipe",
        responsive: {
            0: {
                items: 1
            },
            768: {
                items: 2
            },
            992: {
                items: 3
            }
        },
        loop: true,
        slideBy: 1,
        autoplay: true,
        autoplayTimeout: 15000,
        mouseDrag: true,
        autoplayHoverPause: true,
        autoplayButtonOutput: false,
        nav: true,
        rewind: false,
        autoplayButton: false,
        controls: false,
        navPosition: "bottom"
    });


    if (document.querySelector('.prev-equipe')) {
        document.querySelector('.prev-equipe').onclick = function () {
            slider2.goTo('prev');
        };
    }

    if (document.querySelector('.next-equipe')) {

        document.querySelector('.next-equipe').onclick = function () {
            slider2.goTo('next');
        };
    }

}

if (document.querySelector('.slide-wrapper-dicas')) {

    var slider3 = tns({
        container: ".slide-wrapper-dicas",
        responsive: {
            0: {
                items: 1
            },
            768: {
                items: 2
            },
            992: {
                items: 3
            }
        },
        loop: true,
        slideBy: 1,
        autoplay: true,
        autoplayTimeout: 15000,
        autoplayHoverPause: true,
        mouseDrag: true,
        autoplayButtonOutput: false,
        nav: false,
        rewind: false,
        autoplayButton: false,
        controls: false,
        navPosition: "bottom"
    });

    $(".slide-wrapper-dicas .tns-slide-active .video").eq(1).addClass("video-active");
    $(".slide-wrapper-dicas .tns-slide-active .video").eq(1).removeClass("video-normal");

    function remove_item_active(callback){
        $(".slide-wrapper-dicas .tns-slide-active .video").removeClass("video-active");

        callback();

        return true;
    }

    slider3.events.on('indexChanged', function(){
        remove_item_active(function(){
            $(".slide-wrapper-dicas .tns-slide-active .video").eq(1).addClass("video-active");
            $(".slide-wrapper-dicas .tns-slide-active .video").eq(1).removeClass("video-normal");
            $(".slide-wrapper-dicas .tns-slide-active .video").eq(0).addClass("video-normal");
            $(".slide-wrapper-dicas .tns-slide-active .video").eq(2).addClass("video-normal");
        });
    });

    if (document.querySelector('.prev-dicas')) {
        document.querySelector('.prev-dicas').onclick = function () {
            slider3.goTo('prev');
        };
    }

    if (document.querySelector('.next-dicas')) {

        document.querySelector('.next-dicas').onclick = function () {
            slider3.goTo('next');
        };
    }
}
