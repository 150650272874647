require('lightgallery')

$(document).ready(function() {
    $("#lightgallery").lightGallery(
        {
            galleryId: 1,
            getCaptionFromTitleOrAlt: true,
            exThumbImage: 'data-exthumbimage'
        }
    );

    $("#lightgallery-galeria").lightGallery(
        {
            selector: ".imagem-galeria",
            galleryId: 1,
            getCaptionFromTitleOrAlt: true,
            exThumbImage: 'data-exthumbimage'
        }
    );

    $("#lightgallery-galeria-home").lightGallery(
        {
            selector: ".imagem-galeria-home",
            galleryId: 1,
            getCaptionFromTitleOrAlt: true,
            exThumbImage: 'data-exthumbimage'
        }
    );

    $("#lightgallery2").lightGallery(
        {
            galleryId: 2,
            getCaptionFromTitleOrAlt: true
        }
    );
});
