require('select2');

$(function () {
    $('.select2').select2({
        theme: "bootstrap"
    });

    $('.select2-cidades').select2({
        ajax: {
            url: '/api/municipios',
            dataType: 'json',
            data: function (params) {
                var query = {
                    search: params.term,
                }

                // Query parameters will be ?search=[term]&type=public
                return query;
            }
        },
        theme: "bootstrap"
    });
});

